import { render, staticRenderFns } from "./detailTL.vue?vue&type=template&id=082d653f&"
import script from "./detailTL.vue?vue&type=script&lang=js&"
export * from "./detailTL.vue?vue&type=script&lang=js&"
import style0 from "./detailTL.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "detailTL.vue"
export default component.exports